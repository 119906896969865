import { Nil } from './types';

export function ensureTrailing(val: string, trail: string) {
  return val != null && !val.endsWith(trail) ? `${val}${trail}` : val;
}

export function isStringEmpty(value: Nil<string>) {
  return value == null || value === '';
}

export function hashString(str: string, asHex: boolean = false): string {
  /* jshint bitwise:false */
  let i: number;
  let l: number;
  let hexValue: number = 0x811C9DC5;

  for (i = 0, l = str.length; i < l; i++) {
    hexValue ^= str.charCodeAt(i);
    hexValue += (hexValue << 1) + (hexValue << 4) + (hexValue << 7) + (hexValue << 8) + (hexValue << 24);
  }
  if (asHex) {
    // Convert to 8 digit hex string
    return ('0000000' + (hexValue >>> 0).toString(16)).substr(-8);
  }
  return (hexValue >>> 0).toString();
}

export function upperCaseFirstLetter(word: string) {
  return word.charAt(0).toUpperCase() + word.slice(1);
}

export function lowerCaseLettersExceptFirstLetter(str: string) {
  return str.replace(/\S*/g, function(word) {
    return word.charAt(0) + word.slice(1).toLowerCase();
  });
}