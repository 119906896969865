import { AxiosInstance } from 'axios';
import ControllerBaseService from '@/services/common/controller-base-service';
import { http } from './common/http';

// Uses base http instance
export default class UserService extends ControllerBaseService {
  constructor(axios: AxiosInstance) {
    super('users', axios);
  }

  public static createDefault(): UserService {
    return new UserService(http.instance);
  }
  public getPermissions(): Promise<string[]> {
    return this.httpGet<string[]>('userPermission');
  }
}
