import * as BrowserInfo from 'browser-info';

export class LogViewModel {
  public Message: string = '';
  public BrowserName?: string;
  public BrowserVersion?: string;
  public Url?: string;
  public User?: string;
  public CurrentProjectId?: string;
  public OrgKey?: string;
  public ErrorCode: string = '';
  public ClientErrorId: string;

  constructor() {
    const browserInfo = this.browserInfo();
    this.BrowserName = browserInfo.name;
    this.BrowserVersion = browserInfo.version;
    this.Url = `${window.location.pathname}${window.location.search}`;
    this.ClientErrorId = this.generateClientErrorId(4);
  }

  private generateClientErrorId(length: number): string {
    let text: string = '';
    const alpha: string = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const numeric: string = '0123456789';

    for (let i = 0; i < length; i++) {
      const char: string =
        (i + 1) % 2 === 0
          ? numeric.charAt(Math.floor(Math.random() * numeric.length))
          : alpha.charAt(Math.floor(Math.random() * alpha.length));
      text += char;
    }

    return text;
  }

  private browserInfo(): Record<string, any> {
    return (BrowserInfo && {}.toString.call(BrowserInfo) === '[object Function]') ? BrowserInfo() : {
      name: 'Unknown',
      version: 0
    };
  }
}

export class DebugLogViewModel extends LogViewModel {}

export class ErrorLogViewModel extends LogViewModel {
  public StackTrace?: string;
}

export interface IErrorViewModel {
  message: string | null;
  hash: string;
  id: string;
  redirect: string | null;
  isUnauthorizedResponse?: boolean;
}
