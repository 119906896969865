

























































// Libraries
import { Component, Vue } from 'vue-property-decorator';
// View Models
// Components
import Loading from '@/components/common/Loading.vue';
// Services
import companyStore from '@/store/company';
import UserService from '@/services/user-service';
import { ICustomerResponse } from '@/view-models/company';
import { parentEventBus } from '@/utils/from-parent';

interface ITableColumn {
  key: 'name' | 'primaryContact' | 'emailAddress' | 'menu';
  label: string;
}

@Component({
  name: 'home-page',
  components: {
    Loading,
  },
})
export default class Home extends Vue {
  // Properties
  public manageUsers: boolean = false;
  public currentPage: number = 1;
  public perPage: number = 10;

  // Getters
  public get customerFields(): ITableColumn[] {
    return [
      {
        key: 'name',
        label: this.$t('home.table.columns.name').toString(),
      },
      {
        key: 'primaryContact',
        label: this.$t('home.table.columns.primaryContact').toString(),
      },
      {
        key: 'emailAddress',
        label: this.$t('home.table.columns.emailAddress').toString(),
      },
      {
        key: 'menu',
        label: '',
      },
    ];
  }

  public get companies(): ICustomerResponse[] {
    return companyStore.companies ?? [];
  }
  public get isLoading() {
    return companyStore.isApiCallBusy;
  }

  private async manageUsersToggle() {
    const permissions = await this.getPermissions();
    this.manageUsers = permissions.includes('ManageUsers');
  }
  
  private async getPermissions() : Promise<string[]> {
    const userService = UserService.createDefault();
    return userService.getPermissions();
  }

  // Lifecycle Methods
  public async mounted(): Promise<void> {
    companyStore.clearCompanies();
    companyStore.fetchCompanies();
    await this.manageUsersToggle();
  }

  // Event Handlers
  public goToUserManagement(orgKey: string) {
    parentEventBus().$emit('GLOBAL_ROUTE_CHANGE_WITH_QUERY', {path: '/admin/user-management/', query: orgKey});
  }
}
