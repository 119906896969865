import { v4 as uuidv4 } from 'uuid';

export class Company implements ICompanyViewModel {
  public name: string = '';
  public key: string = uuidv4();
  public customerSites: ISite[] = [
    {
      match: false,
      name: '',
      key: uuidv4(),
      orgKey: this.key,
      equipmentTypes: [],
      supportedProducts: [],
      units: [{match: false, name: '', subUnits: [], key: uuidv4() }],
    },
  ];
  public customerContacts: IContactViewModel[] = [
    {
      isPrimary: true,
      key: uuidv4(),
      name: '',
      role: null,
      location: null,
      email: '',
      otherRole: '',
    },
  ];
  public ssoSettings: ISsoSettingsViewModel | null = null;

  constructor(company?: ICompanyViewModel) {
    if (company != null) {
      this.ssoSettings = null;
      Object.assign(this, company);
      if (this.customerContacts.length === 0) {
        this.customerContacts.push({
          isPrimary: true,
          key: uuidv4(),
          name: '',
          role: null,
          location: null,
          email: '',
          otherRole: '',
        });
      } else {
        this.customerContacts.sort((c1, c2) => {
          if (c1.isPrimary === c2.isPrimary) return 0;
          else if (c1.isPrimary) return -1;
          else return 1;
        });
      }
      
      const sites: ISite[] = this.customerSites;
      if (sites.length === 0) {
        sites.push({
          match: false,
          name: '',
          key: uuidv4(),
          orgKey: company.key,
          equipmentTypes: [],
          supportedProducts: [],
          units: [{match: false, name: '', subUnits: [], key: uuidv4() }],
        });
      }
      sites.forEach((site) => {
        if (site.equipmentTypes == null) {
          site.equipmentTypes = [];
        }
        if (site.supportedProducts == null) {
          site.supportedProducts = [];
        }
        if (site.units.length === 0) {
          site.units = [{match: false, name: '', subUnits: [], key: uuidv4() }];
        }
      });
    }
  }
}
export interface ICompanyViewModel {
  name: string;
  key: string;
  customerSites: ISite[];
  customerContacts: IContactViewModel[];
  ssoSettings: ISsoSettingsViewModel | null;
}
export interface ISite {
  match: boolean;
  name: string;
  key: string;
  orgKey: string;
  equipmentTypes: EquipmentTypeEnum[];
  supportedProducts: SupportedProductsEnum[];
  units: IUnit[];
}
export interface IUnit {
  match: boolean;
  name: string;
  key: string;
  subUnits?: IUnit[];
}
export enum EquipmentTypeEnum {
  DistillationTowers = 'Distillation Towers',
  FiredHeaters = 'Fired Heaters',
  Flares = 'Flares',
}
export enum SupportedProductsEnum {
  Ember = 'EMBER',
  EnergyManager = 'Energy Manager',
  TowerView = 'TowerView',
  ZoloScan = 'ZoloSCAN',
}
export enum SiteOptions {
  equipmentTypes = 'equipmentTypes',
  supportedProducts = 'supportedProducts',
}
export interface IContactViewModel {
  isPrimary: boolean;
  key: string;
  name: string;
  role: RolesEnum | string;
  location: string;
  email: string;
  otherRole?: string; // frontend only
}
export interface ISsoSettingsViewModel {
  isActive: boolean;
  connectionName: string;
  uniqueDomains: string[];
  ssoMappings: ISsoMappingsViewModel;
}
export interface ISsoMappingsViewModel{
  fullNameAttribute: string;
  firstNameAttribute: string;
  lastNameAttribute: string;
  nameAttributeType: SsoMappingAttributeNameTypeEnum;
  emailAttribute: string;
}

export enum SsoMappingAttributeNameTypeEnum {
  firstLastName = 'FirstLastName',
  fullName = 'FullName',
  none = 'None'
}
export enum RolesEnum {
  projectSponsor = 'Project Sponsor',
  unitSupervisor = 'Unit Supervisor',
  firedHeaterEngineer = 'Fired Heater Engineer',
  operationsIT = 'Operations IT/Historian Admin',
  operationsLead = 'Operations Lead',
  operationsLiaison = 'Operations Liaison',
  managerITSiteOperations = 'Manager IT Site Operations',
  processEngineer = 'Process Engineer',
  other = 'Other',
}

export enum CustomerValidations {
  EMPTY_CUSTOMER = 'emptyCustomer',
  EMPTY_CUSTOMER_KEY = 'emptyCustomerKey',
  DUPLICATE_CUSTOMER_KEY = 'duplicateCustomerKey',
  EMPTY_CUSTOMER_NAME = 'emptyCustomerName',
  DUPLICATE_CUSTOMER_NAME = 'duplicateCustomerName',
  EMPTY_CONTACT_KEY = 'emptyContactKey',
  EMPTY_CONTACT_NAME = 'emptyContactName',
  MISSING_REQUIRED_CONTACT = 'missingRequiredContact',
  MISSING_REQUIRED_SITE = 'missingRequiredSite',
  DUPLICATE_SITE_NAME = 'duplicateSiteName',
  EMPTY_SSO_CONNECTION_NAME = 'emptySsoConnectionName',
  DUPLICATE_SSO_CONNECTION_NAME = 'duplicateSsoConnectionName',
  EMPTY_SSO_MAPPINGS = 'emptySsoMappings',
  EMPTY_SSO_UNIQUE_DOMAIN = 'emptySsoUniqueDomain',
  EMPTY_SSO_FIRST_OR_LAST_NAME = 'emptySsoFirstOrLastName',
  EMPTY_SSO_FULL_NAME = 'emptySsoFullName'
}

export interface ICustomerResponse {
  key: string;
  name: string;
  primaryContact: string;
  emailAddress: string;
}

export interface ICompanyLocation {
  value?: null | string;
  text: string,
  disabled?: boolean;
  index?: number;
}
