





























































































// Libraries
import { Component, Vue, Prop } from 'vue-property-decorator';
import { lowerCaseLettersExceptFirstLetter } from '@/utils/string';
// View Models
import {
  EquipmentTypeEnum,
  ISite,
  SupportedProductsEnum,
  SiteOptions,
  ICompanyViewModel,
} from '@/view-models/company';
// Components
import IconButton from '@/components/common/IconButton.vue';
import CheckboxBar from '@/components/common/CheckboxBar.vue';
import OnpointModal from '@/components/common/OnPointModal.vue';
import UnitSection from '@/components/site/UnitSection.vue';
// Stores
import companyStore from '@/store/company';
import { eventBus, Events } from '@/utils/event-bus';

@Component({
  name: 'Site',
  components: {
    IconButton,
    CheckboxBar,
    OnpointModal,
    UnitSection
  },
})
export default class Site extends Vue {
  // VUE.JS Props
  @Prop({ required: true })
  public site: ISite;
  @Prop({ required: true })
  public siteIndex: number;
  @Prop({ required: true })
  public singleSite: boolean;
  @Prop({ required: true })
  public numberOfSites: number;
  @Prop({ required: true })
  public currentCompany: ICompanyViewModel;
  @Prop({ required: true })
  public siteUsedAsLocation: boolean;

  // Refs
  // VUEX
  // Properties
  public showDeleteConfirmModal: boolean = false;
  public showClearConfirmModal: boolean = false;
  public showSiteIsUsedModal: boolean = false;
  public isDuplicateUnit: boolean = false;
  // TODO the values here should be translated
  public equipmentTypes: {text: EquipmentTypeEnum, value: string}[] = [
    { text: EquipmentTypeEnum.DistillationTowers, value: EquipmentTypeEnum.DistillationTowers.replace(/\s/g, '') },
    { text: EquipmentTypeEnum.FiredHeaters, value: EquipmentTypeEnum.FiredHeaters.replace(/\s/g, '') },
    { text: EquipmentTypeEnum.Flares, value: EquipmentTypeEnum.Flares },
  ];
  // TODO the values here should be translated
  public supportedProducts: {text: SupportedProductsEnum, value: string}[] = [
    { text: SupportedProductsEnum.Ember, value:  lowerCaseLettersExceptFirstLetter(SupportedProductsEnum.Ember) },
    { text: SupportedProductsEnum.EnergyManager, value: SupportedProductsEnum.EnergyManager.replace(/\s/g, '') },
    { text: SupportedProductsEnum.TowerView, value: SupportedProductsEnum.TowerView },
    { text: SupportedProductsEnum.ZoloScan, value: SupportedProductsEnum.ZoloScan.replace('SCAN', 'Scan') }
  ];

  // Fields
  private originalSiteName: string = '';

  // Getters
  public get siteOptions() {
    return SiteOptions;
  }
  public get equipmentTypesLabel() {
    return this.$t('customer.equipmentTypes');
  }
  public get supportedProductsLabel() {
    return this.$t('customer.supportedProducts');
  }

  // Lifecycle Handlers
  // beforeCreate(): void {}
  // created(): void {}
  public beforeMount(): void {
    this.originalSiteName = this.site.name;
  }
  // mounted(): void {}
  // beforeUpdate(): void {}
  // updated(): void {}
  // activated(): void {}
  // deactivated(): void {}
  // beforeDestroy(): void {}
  // destroyed(): void {}
  // Private Methods
  private setDuplicate() {
    const isMatch = this.currentCompany.customerSites.some((site: ISite) => site.match);
    companyStore.setDuplicateSite(isMatch);
  }
  private countSites(): Record<string, number> {
    let siteNames = this.currentCompany.customerSites.map((site) => site.name);
    let numOfSites: Record<string, number> = {};
    for (let site of siteNames) {
      if (numOfSites[site]) {
        numOfSites[site]++;
      } else {
        numOfSites[site] = 1;
      }
    }
    return numOfSites;
  }
  public toggleSiteMatch(numOfSites: Record<string, number>) {
    // TODO: this might need improve, trying to avoid the nested loop
    for (let site in numOfSites) {
      for (let siteName in this.currentCompany.customerSites) {
        if (site === this.currentCompany.customerSites[siteName].name) {
          this.currentCompany.customerSites[siteName].match = numOfSites[site] > 1;
        }
      }
    }
  }

  // Helper Methods
  public isDuplicateSite(siteIndex: number): boolean {
    const numOfSites = this.countSites();
    this.toggleSiteMatch(numOfSites);
    this.setDuplicate();
    
    const duplicateIt = this.currentCompany.customerSites[siteIndex].match &&
      this.currentCompany.customerSites[siteIndex].name !== '';
    this.$emit('duplicateSite', duplicateIt);
    return duplicateIt;
  }
  // Event Methods
  public duplicateUnit(match: boolean) : boolean {
    return this.isDuplicateUnit = match;
  }
  public deleteSite() {
    this.$emit('deleteSite', this.siteIndex);
    this.showDeleteConfirmModal = false;
  }
  public clearSite() {
    this.$emit('clearSite', this.siteIndex);
    this.showClearConfirmModal = false;
  }
  public addUnit() {
    this.$emit('addUnit', this.siteIndex);
  }
  public updateSiteOptions(data: { data: any; dataType: string }) {
    this.$emit('updateSiteOptions', {
      data: data.data,
      dataType: data.dataType,
      siteIndex: this.siteIndex,
    });
  }
  public promptDeleteConfirmModal() {
    if (!this.siteUsedAsLocation) {
      this.showDeleteConfirmModal = true;
    } else {
      this.promptSiteIsUsedModal();
    }
  }
  public promptClearConfirmModal() {
     if (!this.siteUsedAsLocation) {
      this.showClearConfirmModal = true;
    } else {
      this.promptSiteIsUsedModal();
    }
  }
  public closeClearModal() {
    this.showClearConfirmModal = false;
  }
  public closeDeleteModal() {
    this.showDeleteConfirmModal = false;
  }
  public promptSiteIsUsedModal() {
    this.showSiteIsUsedModal = true;
  }
  public closeSiteIsUsedModal() {
    this.showSiteIsUsedModal = false;
  }
  public onSiteNameChange(value: string) {
    eventBus.$emit(Events.LocationNameChanged, [this.originalSiteName, value]);
    this.originalSiteName = value;
  }
  // Watchers
  // Emitters
}
