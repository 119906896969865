

























import {Vue, Component, Prop} from 'vue-property-decorator';
import { BootstrapVue } from 'bootstrap-vue';

Vue.use(BootstrapVue);

@Component({
  name: 'onpoint-modal'
})
export default class OnpointModal extends Vue {

  @Prop({required: true})
  private id?: string;
  @Prop({required: true})
  private isOpen?: boolean;
  @Prop({required: false})
  private title?: string;
  @Prop({required: false})
  private buttonOk?: string;
  @Prop({required: false})
  private buttonCancel?: string;
  @Prop({required: false})
  private buttonBackShow?: boolean;
  @Prop({required: false})
  private buttonBack?: string;
  @Prop({required: false})
  private bigModal?: boolean;
  @Prop({required: false})
  private onOk?: () => {};
  @Prop({required: false})
  private onCancel?: () => {};
  @Prop({required: false})
  private onClose?: () => {};
  @Prop({required: false})
  private onBack?: () => {};

  public get modalSize() {
    return this.bigModal ? 'lg' : '';
  }

  public set open(value: boolean) {
    // this.isOpen = value;
  }

  public get open(): boolean {
    return this.isOpen ? this.isOpen : false;
  }

}
