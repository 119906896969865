






















































































// Libraries
import { Component, Vue, Prop } from 'vue-property-decorator';
// View Models
import { IContactViewModel, RolesEnum, ICompanyLocation } from '@/view-models/company';
// Components
import IconButton from '@/components/common/IconButton.vue';
import OnpointModal from '@/components/common/OnPointModal.vue';
// Stores
import companyStore from '@/store/company';
import { ISubscription, subscription, Events } from '@/utils/event-bus';

@Component({
  name: 'contact',
  components: {
    IconButton,
    OnpointModal
  },
})
export default class Contact extends Vue {
  // VUE.JS Props
  @Prop({ required: true })
  public contact!: IContactViewModel;
  @Prop({ required: true })
  public contactIndex!: number;
  @Prop({ required!: true })
  public singleContact: boolean;
  @Prop({ required!: true })
  public companyLocations: ICompanyLocation[];

  // Refs
  // VUEX
  // Properties
  public showDeleteConfirmModal: boolean = false;
  public showClearConfirmModal: boolean = false;
  public isValidEmail: boolean = true;

  // Fields
  private locationNameSub: ISubscription | null = null;

  // Getters
  public get otherIsSelected() {
    return this.contact.role?.toLowerCase() === RolesEnum.other.toLowerCase();
  }
  public get allLocations() {
    return this.companyLocations.filter((location) => location.text);
  }
  public get roleOptions(): (RolesEnum | null)[] {
    const values = Object.values(RolesEnum);
    values.unshift(null);
    return values;
  }

  // Lifecycle Handlers
  // beforeCreate(): void {}
  // created(): void {}
  // beforeMount(): void {}
  public mounted() {
    this.locationNameSub = subscription(
      Events.LocationNameChanged,
      ([prev, current]: [string, string]) => this.onLocationNameChanged(prev, current)
    );
  }
  // beforeUpdate(): void {}
  // public updated(): void {}
  // activated(): void {}
  // deactivated(): void {}
  public beforeDestroy(): void {
    this.locationNameSub?.unsubscribe();
  }
  // destroyed(): void {}

  // Private Methods
  private validateEmail(email: string) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  private setEmailState(state: boolean) {
    companyStore.setGlobalEmailValidation(state);
    this.isValidEmail = state;
  }
  private onLocationNameChanged(prev: string, current: string) {
    if (this.contact.location === prev) {
      this.contact.location = current;
    }
  }

  // Helper Methods
  // Event Methods
  public onEmailChange() {
    this.setEmailState(this.validateEmail(this.contact.email));
  }
  public handleBlur(e: any) {
    if (e.target.value == '') {
      this.setEmailState(false);
    }
  }
  public deleteContact() {
    this.$emit('deleteContact', this.contactIndex);
    this.showDeleteConfirmModal = false;
  }
  public clearContact() {
    this.$emit('clearContact', this.contactIndex);
    this.showClearConfirmModal = false;
  }
  public promptDeleteConfirmModal() {
    this.showDeleteConfirmModal = true;
  }
  public promptClearConfirmModal() {
    this.showClearConfirmModal = true;
  }
  public closeClearModal() { 
    this.showClearConfirmModal = false;
  }
  public closeDeleteModal() {
    this.showDeleteConfirmModal = false;
  }

  // Watchers
  // Emitters
}
