import { AxiosInstance } from 'axios';
import ControllerBaseService from '@/services/common/controller-base-service';
import { ICompanyViewModel, ICustomerResponse } from '@/view-models/company';
import { http } from './common/http';

// Uses base http instance
export default class OrgManagementService extends ControllerBaseService {
  constructor(axios: AxiosInstance) {
    super('customers', axios);
  }

  public static createDefault(): OrgManagementService {
    return new OrgManagementService(http.instance);
  }

  public getCustomers(): Promise<ICustomerResponse[]> {
    return this.httpGet<ICustomerResponse[]>('');
  }

  public getCustomer(companyKey: string): Promise<ICompanyViewModel> {
    return this.httpGet<ICompanyViewModel>(companyKey);
  }

  public updateAccount(payload: ICompanyViewModel): Promise<ICompanyViewModel> {
    return this.httpPut<ICompanyViewModel>('', payload);
  }
  public createAccount(payload: ICompanyViewModel): Promise<ICompanyViewModel> {
    return this.httpPost<ICompanyViewModel>('', payload);
  }

}
