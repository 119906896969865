































































































// Libraries
import companyStore from '@/store/company';
import { IUnit } from '@/view-models/company';
import { Component, Prop, Vue } from 'vue-property-decorator';
// View Models
// Components
import IconButton from '@/components/common/IconButton.vue';
// Services
@Component({
  name: 'UnitSection',
  components: {
    IconButton
  }
})
export default class UnitSection extends Vue {
  // VUE.JS Props
  @Prop({ required: true })
  public units: IUnit[];
  @Prop({ required: true })
  public siteIndex: number;
  @Prop({ required: true })
  public numberOfUnits: number;  

  public checkDuplicateSubUnit(unitIndex: number, subIndex: number) {
    return (this.units[unitIndex].subUnits[subIndex].match && this.units[unitIndex].subUnits[subIndex].name !== '');
  }

  public isDuplicateUnit(unitIndex: number) : boolean {
    let numOfunits = this.countUnits();    
    this.toggleUnitMatch(numOfunits); 
    this.isDuplicateSubUnit(unitIndex);  
    const isMatch = (unit: IUnit) => unit.match;
    if (this.units.some(isMatch)) {
      companyStore.setDuplicateUnit(true);
    } else {
      companyStore.setDuplicateUnit(false);
    }

    if (this.units[unitIndex].match && this.units[unitIndex].name !== '') {
      this.$emit('duplicateUnit', true);
      return true;
    } else {
      this.$emit('duplicateUnit', false);
      return false;
    }
  }
  public addSubUnit(unitIndex: number) {    
    this.$parent.$emit('addSubUnit', {
      siteIndex: this.siteIndex,
      unitIndex,
    });
  }
  public deleteSubUnits(unitIndex: number, subIndex?: number) {
    this.$parent.$emit('deleteSubUnits', { siteIndex: this.siteIndex, unitIndex, subIndex });
  }
  public toggleUnitMatch(numOfunits: any) {
    for (let unit in numOfunits) {      
      for (let unitName in this.units) {
        if (unit === this.units[unitName].name) {
          if (numOfunits[unit] > 1) {
            this.units[unitName].match = true;
          } else {
            this.units[unitName].match = false;
          }
        }
      }
    }    
  }
  public countUnits() {
    let unitNames = [];
    for (let i=0; i<this.units.length; i++) {
      unitNames.push(this.units[i].name);
    }
    let numberOfunits: any = {};
    for (let unit of unitNames) {
      if (numberOfunits[unit]) {        
        numberOfunits[unit]++;        
      } else {        
        numberOfunits[unit] = 1;
      }
    }        
    return numberOfunits; 
  }
  public isDuplicateSubUnit(unitIndex: number) {
    let subUnitsArray = this.units[unitIndex].subUnits;    
    let numOfsubunits = this.countsubUnits(unitIndex);
    this.toggleSubunitMatch(numOfsubunits, subUnitsArray);
    for (let subUnit of subUnitsArray) {
      if (subUnit.match) {
        companyStore.setDuplicateSubUnit(true);
        return;
      } else {
        companyStore.setDuplicateSubUnit(false);
      }
    }
  }

  public countsubUnits(unitIndex: number) {
    let subUnitNames = [];
    let numOfSubunits: any = {};
    for (let subUnit in this.units[unitIndex].subUnits) {
      subUnitNames.push(this.units[unitIndex].subUnits[subUnit].name);
    }
    for (let subUnit of subUnitNames) {
      if (numOfSubunits[subUnit]) {
        numOfSubunits[subUnit]++;
      } else {
        numOfSubunits[subUnit] = 1;
      }
    }       
    return numOfSubunits;  
  }

  public toggleSubunitMatch(numOfSubunits : any, subUnitsArray: any) {
    for (let subUnit in numOfSubunits) {
      for (let subUnitName in subUnitsArray) {                        
        if (subUnit === subUnitsArray[subUnitName].name) {
          if (numOfSubunits[subUnit] > 1) {            
            subUnitsArray[subUnitName].match = true;
          } else {            
            subUnitsArray[subUnitName].match = false;
          }
        }
      }      
    }   
  }

}
