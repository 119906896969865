import { render, staticRenderFns } from "./Error.vue?vue&type=template&id=6797cd04&scoped=true&"
import script from "./Error.vue?vue&type=script&lang=ts&"
export * from "./Error.vue?vue&type=script&lang=ts&"
function injectStyles (context) {
  
  var style0 = require("./Error.vue?vue&type=style&index=0&lang=scss&")
if (style0.__inject__) style0.__inject__(context)
var style1 = require("./Error.vue?vue&type=style&index=1&id=6797cd04&scoped=true&lang=scss&")
if (style1.__inject__) style1.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "6797cd04",
  null
  ,true
)

export default component.exports