import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import {
  Company,
  ICompanyViewModel,
  ICustomerResponse,
  RolesEnum,
  SsoMappingAttributeNameTypeEnum,
} from '@/view-models/company';
import { alphabeticSorter } from '../utils/array-utils';
import store from '.';
import OrgManagementService from '@/services/org-management-service';
import CarCustomerPreferencesService from '@/services/car-customer-preferences-service';

export interface ICompanyModule {
  // State
  companies: ICustomerResponse[];
  company: ICompanyViewModel;
  currentCompany: ICompanyViewModel;
  editMode: boolean;
  isApiCallBusy: boolean;
  allEmailsAreValid: boolean;
  isDuplicateUnit: boolean;
  isDuplicateSubUnit: boolean;
  isDuplicateSite: boolean;
  isDuplicateSsoConnection: boolean;
  saveErrors: string[] | null;

  // Getters
  readonly sitesExist: boolean;
  readonly unnamedSitesExist: boolean;
  readonly sitesAreValid: boolean;
  readonly contactsAreValid: boolean;
  readonly ssoFormIsValid: boolean;

  // Mutations
  deactivateSso(): void;
  addSso(): void;
  setDuplicateSite(duplicate: boolean): void;
  setDuplicateSubUnit(duplicate: boolean): void;
  setDuplicateUnit(duplicate: boolean): void;
  setCompanies(companies: ICustomerResponse[]): void;
  setCompany(company: ICompanyViewModel): void;
  changeMode(val: boolean): void;
  setIsApiCallBusy(): void;
  clearCompanies(): void;
  setGlobalEmailValidation(validity: boolean): void;
  setNewCompany(company?: ICompanyViewModel): void;
  resetSsoMappingsNames(): void;
  setSaveErrors(errors: string[] | null): void;

  // Actions
  fetchCompanies(): Promise<void>;
  fetchCompany(companyKey: string): Promise<void>;
  saveCustomer(): Promise<void>;
}

@Module({ dynamic: true, store, name: 'company' })
export class CompanyModule extends VuexModule implements ICompanyModule {
  // State
  public companies: ICustomerResponse[] = [];
  public company: ICompanyViewModel = null;
  public currentCompany: ICompanyViewModel = null;
  public editMode: boolean = false;
  public isApiCallBusy: boolean = false;
  public allEmailsAreValid: boolean = true;
  public isDuplicateUnit: boolean = false;
  public isDuplicateSubUnit: boolean = false;
  public isDuplicateSite: boolean = false;
  public isDuplicateSsoConnection: boolean = false;
  public saveErrors: string[] | null = null;

  // Getters
  public get sitesExist(): boolean {
    return this.currentCompany.customerSites.length !== 0;
  }

  public get unnamedSitesExist(): boolean {
    let isValid = true;
    this.currentCompany.customerSites?.forEach((site) => {
      site.units?.forEach((unit) => {
        unit.subUnits?.forEach((subUnit) => {
          if (subUnit.name === '') {
            isValid = false;
          }
        });
      });
    });
    return isValid;
  }

  public get sitesAreValid(): boolean {
    let isValid = true;
    if (!this.currentCompany || !this.sitesExist) {
      return (isValid = false);
    }

    this.currentCompany.customerSites.forEach((site) => {
      let duplicateChecks = this.isDuplicateSite || this.isDuplicateUnit || this.isDuplicateSubUnit;
      site.units.forEach(() => {
        isValid = this.unnamedSitesExist;
      });
      if (duplicateChecks || site.name === '') {
        isValid = false;
      }
    });
    return isValid;
  }

  public get contactsAreValid() {
    if (!this.currentCompany || this.currentCompany.customerContacts.length === 0) {
      return false;
    }

    let isValid = true;
    this.currentCompany.customerContacts.forEach((contact) => {
      switch (true) {
        case contact.name === '':
        case contact.role == null:
        case contact.role === '':
        case (contact.role === RolesEnum.other && contact.otherRole === '') ||
          (contact.role === RolesEnum.other && contact.otherRole == null):
        case contact.location == null:
        case contact.location === '':
        case contact.email === '':
          isValid = false;
          break;
        default:
          isValid = true;
      }
    });
    return isValid;
  }

  public get ssoFormIsValid() {
    let isValid = true;
    if (this.currentCompany.ssoSettings != null && this.currentCompany.ssoSettings.isActive) {
      isValid =
        this.currentCompany.ssoSettings.connectionName !== '' &&
        this.currentCompany.ssoSettings.uniqueDomains &&
        this.currentCompany.ssoSettings.uniqueDomains.length > 0 &&
        this.currentCompany.ssoSettings.ssoMappings.emailAttribute !== '';
      if (isValid) {
        const nameType = this.currentCompany.ssoSettings.ssoMappings.nameAttributeType;
        const mappings = this.currentCompany.ssoSettings.ssoMappings;
        if (nameType === SsoMappingAttributeNameTypeEnum.firstLastName) {
          isValid = mappings.firstNameAttribute !== '' && mappings.lastNameAttribute !== '';
        } else if (nameType === SsoMappingAttributeNameTypeEnum.fullName) {
          isValid = mappings.fullNameAttribute !== '';
        }
      }
    }
    return isValid;
  }

  // Mutations
  @Mutation
  public deactivateSso(): void {
    this.currentCompany.ssoSettings = null;
  }
  @Mutation
  public addSso() {
    this.currentCompany.ssoSettings = {
      isActive: true,
      connectionName: '',
      uniqueDomains: [],
      ssoMappings: {
        nameAttributeType: SsoMappingAttributeNameTypeEnum.firstLastName,
        fullNameAttribute: '',
        firstNameAttribute: '',
        lastNameAttribute: '',
        emailAttribute: '',
      },
    };
  }
  @Mutation
  public setDuplicateSite(duplicate: boolean): void {
    this.isDuplicateSite = duplicate;
  }
  @Mutation
  public setDuplicateSubUnit(duplicate: boolean): void {
    this.isDuplicateSubUnit = duplicate;
  }
  @Mutation
  public setDuplicateUnit(duplicate: boolean): void {
    this.isDuplicateUnit = duplicate;
  }
  @Mutation
  public setCompanies(companies: ICustomerResponse[]): void {
    companies.sort((x, y) => (x.name.toLowerCase() > y.name.toLowerCase() ? 1 : -1));
    this.companies = companies;
  }
  @Mutation
  public setCompany(company: ICompanyViewModel): void {
    var invalidSso =
      company.ssoSettings != null &&
      !company.ssoSettings.isActive &&
      company.ssoSettings.connectionName == null &&
      company.ssoSettings.ssoMappings?.nameAttributeType == SsoMappingAttributeNameTypeEnum.none;
    if (invalidSso) {
      company.ssoSettings = null;
    }
    company.customerSites.sort(alphabeticSorter((site) => site.name));
    this.company = company;
  }
  @Mutation
  public changeMode(val: boolean): void {
    this.editMode = val;
  }
  @Mutation
  public setIsApiCallBusy(): void {
    this.isApiCallBusy = !this.isApiCallBusy;
  }
  @Mutation
  public clearCompanies(): void {
    this.companies = [];
  }
  @Mutation
  public setGlobalEmailValidation(validity: boolean): void {
    this.allEmailsAreValid = validity;
  }
  @Mutation
  public setNewCompany(company?: ICompanyViewModel): void {
    this.currentCompany = new Company(company);
  }
  @Mutation
  public resetSsoMappingsNames(): void {
    this.currentCompany.ssoSettings.ssoMappings.firstNameAttribute = '';
    this.currentCompany.ssoSettings.ssoMappings.lastNameAttribute = '';
    this.currentCompany.ssoSettings.ssoMappings.fullNameAttribute = '';
  }
  @Mutation
  public setSaveErrors(errors: string[] | null): void {
    this.saveErrors = errors;
  }

  // Actions
  @Action({ rawError: true })
  public async fetchCompanies(): Promise<void> {
    try {
      this.setIsApiCallBusy();
      const companies = await OrgManagementService.createDefault().getCustomers();
      this.setIsApiCallBusy();
      this.setCompanies(companies);
    } catch (e) {
      this.setIsApiCallBusy();
    }
  }
  @Action({ rawError: true })
  public async fetchCompany(companyKey: string): Promise<void> {
    try {
      this.setIsApiCallBusy();
      const company: ICompanyViewModel = await OrgManagementService.createDefault().getCustomer(companyKey);
      this.setIsApiCallBusy();

      this.setCompany(new Company(company));
    } catch (e) {
      this.setIsApiCallBusy();
    }
  }
  @Action({ rawError: true })
  public async saveCustomer(): Promise<void> {
    this.setSaveErrors(null);
    const savingCustomer = new Company(this.currentCompany);
    try {
      const service = OrgManagementService.createDefault();
      this.setIsApiCallBusy();
      if (this.editMode) {
        await service.updateAccount(savingCustomer);
      } else {
        const savedCustomer = await service.createAccount(savingCustomer);
        const carService = CarCustomerPreferencesService.createDefault();
        await carService.createDefaultCustomerPreference(savedCustomer.key, savedCustomer.name);
      }
    } catch (e) {
      const result = (e as any)?.response as Record<string, string>;

      this.setSaveErrors(Object.keys(result));
    } finally {
      this.setIsApiCallBusy();
    }
  }
}

export default getModule(CompanyModule, store);
