import LoggerService from '@/services/logger-service';
import { IErrorModule } from '@/store/error';
import { ErrorBusMessage, IErrorBusMessage, IErrorMessageConfig } from '@/view-models/error/error-model';
import { IErrorViewModel } from '@/view-models/logger';
import { APP_TAG, inParentApp, parentEventBus } from './from-parent';
import { errorToErrorLogViewModel } from './logger';

const EVENT_NAME = 'GLOBAL_ERROR';

export interface IGenericError {
  error: {
    response: {
      status: number;
    };
    message?: string;
    error_description?: string;
  };
  errorString: string;
}

export default class ErrorParser {
  constructor(private readonly payload: IGenericError) {}

  get asClientError(): string {
    switch (this.payload.error.response.status) {
      case 400:
        return `${this.payload.errorString} Bad request.`;
      case 404:
        return `${this.payload.errorString} Service not found.`;
      case 401:
      case 403:
        return `${this.payload.error.message ?? this.payload.errorString} Access denied.`;
      default:
        return this.parseError();
    }
  }

  get asServiceError(): string {
    const message = `${this.payload.errorString}${this.payload.error.message  ?? ''} `;
    switch (this.payload.error.response.status) {
      case 500:
        return `${message} Service error.`;
      case 501:
      case 502:
      case 503:
      case 504:
        return `${message} Service unavailable.`;
      default:
        return this.parseError();
    }
  }

  private parseError(): string {
    const error = this.payload.error.message
      ?? this.payload.error.error_description ?? this.payload.errorString;
    return `${error}.`;
  }
}


export const emitError = (state: IErrorModule) => {
  // If inside the parent app, then send the error to the parent app.  Else, handle the error inside the child app.
  if (inParentApp()) {
    const config: IErrorMessageConfig = {
      logSilently: false,
      showMessage: true
    };
    const emitEvent: IErrorBusMessage = new ErrorBusMessage(APP_TAG, state.error, config);
    parentEventBus()?.$emit(EVENT_NAME, emitEvent);
  } else {
    // Show internal error modal if handle error is true
    if (state.handleError) {
      state.showModal = true;
    }
  }
};

export function globalErrorHandler(
  error: any, sendLog: boolean = true, logSilently: boolean = false, tag: string = null
) {
  // eslint-disable-next-line no-console
  // console.error(error);
  if (error == null) {
    return;
  }

  const entry = errorToErrorLogViewModel(error);
  if (tag != null) {
    entry.Message = `[${tag}]: ${entry.Message}`;
  }

  const logPromise = sendLog
    ? LoggerService.createDefault().postError(entry)
    : Promise.resolve();

  if ((sendLog && !logSilently) || !sendLog) {
    logPromise.then(() => {
      const isUnauthorizedResponse = error.response && error.response.data &&
        error.response.data.className === 'KES.Common.Exceptions.UserUnauthorizedAccessException';
      const errorViewModel: IErrorViewModel = {
        message: entry.Message,
        hash: entry.ErrorCode,
        id: entry.ClientErrorId,
        redirect: null,
        isUnauthorizedResponse
      };
      parentEventBus()?.$emit('error-modal-event', errorViewModel);
    });
  }
}
