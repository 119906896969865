import { AxiosInstance } from 'axios';
import ControllerBaseService from '@/services/common/controller-base-service';
import { carHttp } from './common/http';

export default class CarCustomerPreferencesService extends ControllerBaseService {
  constructor(axios: AxiosInstance) {
    super('dv/customerPreference', axios);
  }

  public static createDefault(): CarCustomerPreferencesService {
    return new CarCustomerPreferencesService(carHttp.instance);
  }

  public createDefaultCustomerPreference(customerKey: string, customerName: string): Promise<void> {
    return this.httpPost<void>('customerPreference/create-default', {
      customerKey,
      customerName
    });
  }
}
