


































// Libraries
import { Component, Vue } from 'vue-property-decorator';
import { cloneDeep } from 'lodash';
import { isStringEmpty } from '@/utils/string';
// Components
import Loading from '@/components/common/Loading.vue';
import CustomerDetails from '@/components/customerDetails/CustomerDetails.vue';
import ButtonGroup from '@/components/buttons/ButtonGroup.vue';
// Stores
import companyStore from '@/store/company';
import { eventBus, Events, ISubscription, subscription } from '@/utils/event-bus';

@Component({
  name: 'customer-view',
  components: {
    CustomerDetails,
    Loading,
    ButtonGroup,
  },
})
export default class CustomerViewPage extends Vue {
  public duplicateCompanyName: boolean = false;
  public originalOrgName: string = '';
  public duplicateCompanySub: ISubscription | null = null;

  // Getters
  public get currentCompanyName(): string {
    return this.currentCompany.name;
  }
  public get currentCompany() {
    return companyStore.currentCompany;
  }
  public get emailsAreValid(): boolean {
    return companyStore.allEmailsAreValid;
  }
  public get headerTitle(): string {
    const path = this.editMode ? 'customer.editCustomerAccount' : 'customer.addCustomerAccount';
    return this.$t(path).toString();
  }
  public get editMode(): boolean {
    return companyStore.editMode;
  }
  public get companyIsValid(): boolean {
    return (
      this.currentCompany.name !== '' &&
      this.contactsAreValid &&
      this.sitesAreValid &&
      this.emailsAreValid &&
      this.companyIsPristine &&
      this.ssoFormIsValid
    );
  }
  public get contactsAreValid(): boolean {
    return companyStore.contactsAreValid;
  }
  public get sitesAreValid(): boolean {
    return companyStore.sitesAreValid;
  }
  public get ssoFormIsValid(): boolean {
    return companyStore.ssoFormIsValid;
  }
  public get isLoading() {
    return this.editMode && companyStore.isApiCallBusy;
  }
  public get companyIsPristine() {
    return JSON.stringify(companyStore.company) !== JSON.stringify(this.currentCompany);
  }

  public mounted() {
    this.duplicateCompanySub = subscription(
      Events.DuplicateCompanyExist,
      () => this.onDuplicateCompanyExist()
    );
  }
  public beforeDestroy(): void {
    this.duplicateCompanySub?.unsubscribe();
  }
  public async beforeMount(): Promise<void> {
    companyStore.setNewCompany();

    const customerKey = this.$route.params.key;
    companyStore.changeMode(!isStringEmpty(customerKey));
    if (this.editMode) {
      await companyStore.fetchCompany(customerKey);
      const newCompany = cloneDeep(companyStore.company);
      companyStore.setNewCompany(newCompany);
      this.originalOrgName = this.currentCompany.name;
    }
  }
  public scrollToTop() {
    const top = (this.$refs.top as unknown) as HTMLElement;
    this.$nextTick(() => {
      top.scrollIntoView({ behavior: 'smooth' });
    });
  }
  public onDuplicateCompanyExist() {
    this.duplicateCompanyName = true;
    this.scrollToTop();
  }
  // Event Methods
  public onCompanyNameChange(value: string) {
    eventBus.$emit(Events.LocationNameChanged, [this.originalOrgName, value]);
    this.originalOrgName = value;
  }
}
