import { DebugLogViewModel, ErrorLogViewModel } from '@/view-models/logger';
import { AxiosInstance } from 'axios';
import ControllerBaseService from './common/controller-base-service';
import { http } from './common/http';

export default class LoggerService extends ControllerBaseService {
  constructor(axios: AxiosInstance) {
    super('logger', axios);
  }

  public static createDefault(): LoggerService {
    return new LoggerService(http.instance);
  }

  public async postError(error: ErrorLogViewModel): Promise<void> {
    try {
      await this.httpPost<void>('error', error);
    } catch (err) {
      errorHandler(err);
    }
  }

  public async postDebug(message: DebugLogViewModel): Promise<void> {
    try {
      await this.httpPost<void>('debug', message);
    } catch (err) {
      errorHandler(err);
    }
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const errorHandler = (_error: any): void => {
};
