

























































































































// Libraries
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Events, ISubscription, subscription } from '@/utils/event-bus';
// View Models
import { ISsoSettingsViewModel, ISsoMappingsViewModel } from '@/view-models/company';
// Components
import IconButton from '@/components/common/IconButton.vue';
// Stores
import companyStore from '@/store/company';

@Component({
  name: 'SsoForm',
  components: {
    IconButton,
  },
})
export default class SsoForm extends Vue {
  private _commaSeparatedUniqueDomains: string | undefined;
  public duplicateConnectionNameSub: ISubscription | null = null;
  public duplicateConnectionName: boolean = false;
  // VUE.JS Props
  @Prop({ required: true })
  public customerSsoSettings!: ISsoSettingsViewModel;

  public get commaSeparatedUniqueDomains(): string {
    if (!this._commaSeparatedUniqueDomains) {
      this.commaSeparatedUniqueDomains = this.customerSsoSettings.uniqueDomains.join(', ');
    }

    return this._commaSeparatedUniqueDomains;
  }

  public set commaSeparatedUniqueDomains(value: string) {
    this._commaSeparatedUniqueDomains = value;
    const uniqueDomains = this._commaSeparatedUniqueDomains.split(',');
    this.customerSsoSettings.uniqueDomains = uniqueDomains
      .map((uniqueDomain) => uniqueDomain.trim())
      .filter((value) => value !== '');
  }

  // Refs
  // VUEX
  // Properties
  // Fields
  // Getters
  public get ssoMappings(): ISsoMappingsViewModel {
    return this.customerSsoSettings.ssoMappings;
  }
  public get editMode(): boolean {
    return companyStore.editMode;
  }
  @Watch('ssoMappings.nameAttributeType')
  public radioButtonChanged() {
    if (!this.editMode) {
      this.clearForm();
    }
  }
  // Lifecycle Handlers
  // beforeCreate(): void {}
  // created(): void {}
  // beforeMount(): void {}
  public mounted() {
    this.duplicateConnectionNameSub = subscription(Events.DuplicateSsoConnectionName, () =>
      this.onDuplicateConnectionName()
    );
  }
  // beforeUpdate(): void {}
  // public updated(): void {}
  // activated(): void {}
  // deactivated(): void {}
  // public beforeDestroy(): void {}
  // destroyed(): void {}
  // Private Methods
  // Helper Methods
  public onDuplicateConnectionName() {
    this.customerSsoSettings.connectionName = '';
    this.duplicateConnectionName = true;
  }
  public clearForm() {
    companyStore.currentCompany.ssoSettings.connectionName = '';
    companyStore.currentCompany.ssoSettings.uniqueDomains = [];
    companyStore.currentCompany.ssoSettings.ssoMappings.firstNameAttribute = '';
    companyStore.currentCompany.ssoSettings.ssoMappings.lastNameAttribute = '';
    companyStore.currentCompany.ssoSettings.ssoMappings.emailAttribute = '';
    companyStore.currentCompany.ssoSettings.ssoMappings.fullNameAttribute = '';
  }
  // Event Methods
}
