









import { Vue, Component, Prop, Emit } from 'vue-property-decorator';

// THIS IS A COPY!
// For now since we can't consume DSM components as packages, we copy them from the CommonLibrary
@Component({
  name: 'icon-button',
})
export default class IconButton extends Vue {
  @Prop({ default: false })
  public readonly disabled!: boolean;
  @Prop({ required: true })
  public readonly iconClass!: string;
  @Prop()
  public readonly text?: string;

  @Emit('click')
  public onClick(eventEmit: MouseEvent) {}
}
