





























// Libraries
import { Component, Vue } from 'vue-property-decorator';
import { changeParentRoute } from '@/utils/from-parent';
// View Models
// Components
import Breadcrumb from '@/components/common/Breadcrumb.vue';
import companyStore from '@/store/company';
// Services
@Component({
  name: 'header-component',
  components: {
    Breadcrumb,
  },
})
export default class Header extends Vue {
  // VUE.JS Props
  // VUEX
  // Properties
  private adminLink: string = '/admin';
  // Fields
  // Getters
  public get editMode(): boolean {
    return companyStore.editMode;
  }
  // Lifecycle Handlers
  // beforeCreate(): void {}
  // created(): void {}
  // beforeMount(): void {}
  // mounted(): void {}
  // beforeUpdate(): void {}
  // updated(): void {}
  // beforeDestroy(): void {}
  // destroyed(): void {}
  // Private Methods
  private redirectToAdmin() {
    changeParentRoute(this.adminLink);
  }
  // Helper Methods
  // Event Methods
  // Watchers
  // Emitters
}
