





























import companyStore from '@/store/company';
import { CustomerValidations } from '@/view-models/company';
import { eventBus, Events } from '@/utils/event-bus';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'ButtonGroup'
})

export default class ButtonGroup extends Vue {
  // VUE.JS Props
  @Prop({ required: true })
  public companyIsValid: boolean;
  @Prop({ required: true })
  public editMode: boolean;

  public processingData: boolean = false;

  public get currentCompany() {
    return companyStore.currentCompany;
  }
  
  public resetFormState() {
    companyStore.setDuplicateUnit(false);
    companyStore.setDuplicateSite(false);
    companyStore.setDuplicateSubUnit(false);
  }
  public cancelPressed() {
    this.resetFormState();
    this.$router.push('/');
  }
  public async saveCustomer() {
    this.processingData = true;
    await companyStore.saveCustomer();
    if ((companyStore.saveErrors?.length ?? 0) > 0) {
      for (const error of companyStore.saveErrors) {
        switch (error) {
          case CustomerValidations.DUPLICATE_SSO_CONNECTION_NAME:
            this.duplicateSsoConnectionName();
            break;
          case CustomerValidations.DUPLICATE_CUSTOMER_NAME:
            this.duplicateCompanyExist();
            break;
        }
      }
    } else {
      this.resetFormState();
      this.$router.push('/');
    }
    this.processingData = false;
  }
  public duplicateCompanyExist() {
    this.currentCompany.name = '';
    eventBus.$emit(Events.DuplicateCompanyExist);
  }
  public duplicateSsoConnectionName() {
    eventBus.$emit(Events.DuplicateSsoConnectionName);
  }
}
