import { Nil } from '@/utils/types';
import { AxiosInstance, AxiosRequestConfig } from 'axios';

export default class BaseService {
  constructor(
    private readonly http: AxiosInstance
  ) {}

  public async httpGet<T>(uri: string, query?: Record<string, any>): Promise<T> {
    return (await this.http.get<T>(this.url(uri), processQuery(query))).data;
  }

  public async httpPost<T>(uri: string, payload?: any): Promise<T> {
    return (await this.http.post<T>(this.url(uri), payload)).data;
  }

  public async httpPut<T>(uri: string, payload?: any): Promise<T> {
    return (await this.http.put<T>(this.url(uri), payload)).data;
  }

  public async httpDelete<T>(uri: string, query?: Record<string, any>): Promise<T> {
    return (await this.http.delete<T>(this.url(uri), processQuery(query))).data;
  }

  protected url(uri?: string): string {
    return uri ?? '';
  }
}

function processQuery(query?: Record<string, any>): Nil<AxiosRequestConfig> {
  return query == null ? undefined : { params: query };
}
