








import { Component, Vue, Prop } from 'vue-property-decorator';
@Component({
  name: 'checkbox-bar',
})
export default class CheckboxBar extends Vue {
  // VUE.JS Props
  @Prop({ required: true })
  public data: any;
  @Prop({ required: true })
  public label: string;
  @Prop({ required: true })
  public options: string[];
  @Prop({ required: true })
  public dataType: string;

  public get formData() {
    return this.data;
  }
  public set formData(data) {
    this.$emit('dataChanged', {
      data,
      dataType: this.dataType,
    });
  }
}
