import Vue from 'vue';

export const eventBus = new Vue();

export enum Events {
  LocationNameChanged = 'editor::location-name-change',
  DuplicateCompanyExist = 'editor::duplicate-company-exist',
  DuplicateSsoConnectionName = 'editor::duplicate-sso-connection-name',
}

export enum ParentEvents {
  RouteChange = 'onpoint::routeChange',
  ActiveCustomerChanged = 'user::ActiveCustomerChanged'
}

export function configureEventBuses() {
  eventBus.$off();
}

export interface ISubscription {
  unsubscribe: () => void;
}

export const subscription = (event: Events | ParentEvents, callback: (...args: any[]) => void): ISubscription => {
  eventBus.$on(event.toString(), callback);

  return {
    unsubscribe() {
      eventBus.$off(event.toString(), callback);
    }
  };
};
