


























































































































// Libraries
import { Component, Vue } from 'vue-property-decorator';
import { v4 as uuidv4 } from 'uuid';
// View Models
import { ICompanyLocation } from '@/view-models/company';
import {
  EquipmentTypeEnum,
  ICompanyViewModel,
  RolesEnum,
  SiteOptions,
  SupportedProductsEnum,
} from '@/view-models/company';
// Components
import { Events, ISubscription, subscription } from '@/utils/event-bus';
import IconButton from '@/components/common/IconButton.vue';
import Contact from '@/components/contact/Contact.vue';
import Site from '@/components/site/Site.vue';
import SsoForm from '@/components/ssoForm/SsoForm.vue';
// Stores
import companyStore from '@/store/company';

@Component({
  name: 'CustomerDetails',
  components: {
    IconButton,
    Site,
    Contact,
    SsoForm,
  },
})
export default class CustomerDetails extends Vue {
  // Properties
  public isDuplicateSite: boolean = false;
  public siteBodyIsOpen: boolean = false;
  public contactBodyIsOpen: boolean = false;
  public ssoBodyIsOpen: boolean = false;
  // Fields

  private ssoBodyIsOpenSub: ISubscription | null = null;

  // Getters
  public get company(): ICompanyViewModel {
    return companyStore.currentCompany;
  }
  public get ssoIsActive() {
    return companyStore.currentCompany?.ssoSettings?.isActive ?? false;
  }
  public set ssoIsActive(active: boolean) {
    if (active) {
      companyStore.addSso();
    } else {
      companyStore.deactivateSso();
    }
  }
  public get contactsAreValid(): boolean {
    return companyStore.contactsAreValid;
  }
  public get ssoFormIsValid(): boolean {
    return companyStore.ssoFormIsValid;
  }
  public get sitesAreValid(): boolean {
    return companyStore.sitesAreValid;
  }
  public get editMode(): boolean {
    return companyStore.editMode;
  }
  public get companyLocations() {
    let locations: ICompanyLocation[] = [];
    locations = this.company.customerSites.map((site, index) => {
      return { text: site.name, value: site.name, index };
    });
    locations.unshift(
      { value: null, text: this.$t('contact.chooseLocation') as string, disabled: true },
      { value: this.company.name, text: this.company.name }
    );
    return locations;
  }
  public get toggleSiteIconClass(): string {
    return `mdi-${this.siteBodyIsOpen ? 'minus' : 'plus'}-box-outline`;
  }
  public get toggleContactIconClass(): string {
    return `mdi-${this.contactBodyIsOpen ? 'minus' : 'plus'}-box-outline`;
  }
    public get toggleSsoFormIconClass(): string {
    return `mdi-${this.ssoBodyIsOpen ? 'minus' : 'plus'}-box-outline`;
  }

  public mounted() {
    this.ssoBodyIsOpenSub = subscription(
      Events.DuplicateSsoConnectionName,
      () => {
        this.ssoBodyIsOpen = true;
      }
    );
  }
  public beforeDestroy() {
    this.ssoBodyIsOpenSub?.unsubscribe();
  }

  // Helper Methods
  public siteUsedAsLocation(name: string) {
    let siteIsUsed = false;
    this.company.customerContacts.forEach((contact) => {
      if (contact.location === name) {
        siteIsUsed = true;
      }
    });
    return siteIsUsed;
  }
  // Event Methods
  public duplicateSite(match: boolean) : boolean {
    return this.isDuplicateSite = match;
  }

  public addSite(): void {
    this.company.customerSites.push({
      match: false,
      name: '',
      key: uuidv4(),
      orgKey: this.company.key,
      equipmentTypes: [],
      supportedProducts: [],
      units: [
        {
          match: false,
          name: '',
          key: uuidv4(),
          subUnits: [],
        },
      ],
    });
  }
  public deleteSite(siteIndex: number): void {
    this.company.customerSites = this.company.customerSites.filter((site, index) => index !== siteIndex);
  }
  public clearSite(siteIndex: number) {
    Vue.set(this.company.customerSites, siteIndex, {
      name: '',
      key: uuidv4(),
      equipmentTypes: [],
      supportedProducts: [],
      units: [
        {
          match: false,
          name: '',
          key: uuidv4(),
          subUnits: [],
        },
      ],
    });
  }
  public addUnit(siteIndex: number): void {
    if (this.company.customerSites[siteIndex].units.length < 3) {
      this.company.customerSites[siteIndex].units.push({ match: false, name: '', subUnits: [], key: uuidv4() });
    }
  }

  public deleteSubUnits(payload: { siteIndex: number; unitIndex: number; subIndex?: number }): void {
    if (payload.subIndex == null) {
      this.company.customerSites[payload.siteIndex].units.splice(payload.unitIndex, 1);
    } else {
      this.company.customerSites[payload.siteIndex].units[payload.unitIndex].subUnits.splice(payload.subIndex);
    }
  }

  public addSubUnit(payload: { siteIndex: number; unitIndex: number }): void {
    if (this.company.customerSites[payload.siteIndex].units[payload.unitIndex].subUnits.length < 3) {
      this.company.customerSites[payload.siteIndex].units[payload.unitIndex].subUnits.push({
        match: false,
        name: '',
        key: uuidv4(),
      });
    }
  }

  public updateSiteOptions(data: { data: any; dataType: SiteOptions; siteIndex: number }) {
    if (data.dataType === SiteOptions.equipmentTypes) {
      Vue.set(this.company.customerSites[data.siteIndex], SiteOptions.equipmentTypes, data.data as EquipmentTypeEnum[]);
    } else if (data.dataType === SiteOptions.supportedProducts) {
      Vue.set(
        this.company.customerSites[data.siteIndex],
        SiteOptions.supportedProducts,
        data.data as SupportedProductsEnum[]
      );
    }
  }

  public contactRoleUpdated(data: { contactIndex: number; role: string | RolesEnum }) {
    this.company.customerContacts[data.contactIndex].role = data.role;
  }

  public clearContact(contactIndex: number) {
    Vue.set(this.company.customerContacts, contactIndex, {
      isPrimary: contactIndex === 0 ? true : false,
      name: '',
      role: null,
      location: null,
      email: '',
      key: uuidv4(),
    });
  }

  public deleteContact(contactIndex: number) {
    this.company.customerContacts = this.company.customerContacts.filter((contact, index) => index !== contactIndex);
  }

  public addContact() {
    this.company.customerContacts.push({
      isPrimary: false,
      name: '',
      role: null,
      location: null,
      email: '',
      key: uuidv4(),
    });
  }

  public toggleSite() {
    this.siteBodyIsOpen = !this.siteBodyIsOpen;
  }

  public toggleSsoForm() {
    this.ssoBodyIsOpen = !this.ssoBodyIsOpen;
  }

  public toggleContact() {
    this.contactBodyIsOpen = !this.contactBodyIsOpen;
  }
}
